import _styled from "styled-components";
/* eslint-disable react/jsx-props-no-spreading */
import { SUPPORTED_EXECUTABLE_LANGUAGES } from '@kindo/universal';
import ReactMarkdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { xonokai } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import { visit } from 'unist-util-visit';
import { Button, ButtonType, Color, Icon, Size, Typography, TypographySize, TypographyWeight } from '../core';
import { TypographyWrap } from '../core/Typography';
import { CopyButton } from '../shared';
import { convertTableToTSV, saveTableAsCSV } from './Markdown.utils';
import { useOrgSettings } from '~/hooks';
import { nextTrpc } from '~/trpc';
type MarkdownProps = {
  children: string;
  chatMessageId?: string;
  codeBlockTitle?: string;
  isStreaming?: boolean;
  styleDocsComponents?: boolean;
  textColor?: Color;
};
enum CalloutType {
  NOTE = 'NOTE',
  TIP = 'TIP',
  WARNING = 'WARNING',
}
const InlineCode = _styled.code<{
  styleDocsComponents: boolean;
}>(({
  styleDocsComponents
}) => [{
  "borderRadius": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 246 / var(--tw-bg-opacity))",
  "padding": "0.25rem"
}, styleDocsComponents ? {
  "--tw-bg-opacity": "0.5",
  "--tw-text-opacity": "1",
  "color": "rgb(62 61 65 / var(--tw-text-opacity))"
} : {
  "--tw-text-opacity": "1",
  "color": "rgb(239 68 68 / var(--tw-text-opacity))"
}]);
const MultilineInlineCode = _styled.code({
  "display": "block",
  "overflowX": "auto",
  "borderRadius": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 246 / var(--tw-bg-opacity))",
  "padding": "0.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(239 68 68 / var(--tw-text-opacity))"
});
const SyntaxHighlighterHeader = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderTopLeftRadius": "0.5rem",
  "borderTopRightRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(62 61 65 / var(--tw-bg-opacity))",
  "paddingLeft": "0.75rem"
});
const SyntaxHighlighterHeaderTitle = _styled.div({
  "display": "flex"
});
const SyntaxHighlighterHeaderButtons = _styled.div({
  "display": "flex"
});
const TableMarkdownContainer = _styled.div({
  "borderRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(238 242 255 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
  "padding": "0.75rem",
  "paddingTop": "0px"
});
const TableButtonsWrapper = _styled.div({
  "marginBottom": "1rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "flex-end",
  "gap": "0.5rem",
  "borderBottomWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(238 242 255 / var(--tw-border-opacity))"
});
const TableContainer = _styled.div({
  "overflowX": "auto",
  "borderRadius": "0.5rem",
  "paddingTop": "0px"
});
const Table = _styled.table({
  "width": "100%"
});
const TableHeader = _styled.th({
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const TableData = _styled.td({
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const StyledTable = _styled.table({
  "overflow": "auto",
  "borderTopLeftRadius": "0.5rem",
  "borderTopRightRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(62 61 65 / var(--tw-bg-opacity))"
});
const StyledTableHeader = _styled.th({
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const StyledTableData = _styled.td({
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const CalloutContainer = _styled.div<{
  type: CalloutType;
}>(({
  type
}) => [{
  "marginTop": "1rem",
  "marginBottom": "1rem",
  "borderTopRightRadius": "0.25rem",
  "borderBottomRightRadius": "0.25rem",
  "borderLeftWidth": "4px",
  "padding": "1rem"
}, type === CalloutType.NOTE && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(59 130 246 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(219 234 254 / var(--tw-bg-opacity))"
}, type === CalloutType.WARNING && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(234 179 8 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(254 249 195 / var(--tw-bg-opacity))"
}, type === CalloutType.TIP && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(34 197 94 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(220 252 231 / var(--tw-bg-opacity))"
}]);
const getMarkdownComponents = ({
  chatMessageId,
  codeBlockTitle,
  textColor,
  styleDocsComponents,
  isStreaming
}: {
  chatMessageId: string | undefined;
  codeBlockTitle: string | undefined;
  isStreaming: boolean;
  styleDocsComponents: boolean;
  textColor: Color | undefined;
}): Partial<Components> => ({
  p: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.SMALL} />,
  // Treat H4-H6 as medium text (same as H3), the LLM is instructed not to use H4-H6
  h6: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h5: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h4: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  // The LLM has been instructed to return H1-H3 for headings
  h3: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h2: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.LARGE} weight={TypographyWeight.SEMI_BOLD} />,
  h1: ({
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} />,
  ul: ({
    ...props
  }) => <ul {...props as any} css={{
    "listStylePosition": "outside",
    "listStyleType": "disc",
    "paddingLeft": "0.5rem"
  }} />,
  ol: ({
    ...props
  }) => <ol {...props as any} css={{
    "listStylePosition": "outside",
    "listStyleType": "decimal",
    "paddingLeft": "0.5rem"
  }} />,
  // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-shadow
  li: ({
    children,
    ...props
  }) => <li {...props as any} css={{
    "marginLeft": "1.5rem"
  }}>
      <Typography color={textColor} size={TypographySize.SMALL}>
        {children}
      </Typography>
    </li>,
  // https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  code: ({
    node,
    className,
    children,
    ref,
    ...props
  }) => {
    const executeCodeMutation = nextTrpc.chatMessage.executeCode.useMutation();
    const codeIndex = 'data-code-index' in props && typeof props['data-code-index'] === 'number' ? props['data-code-index'] : undefined;
    const codeLanguage = 'data-code-language' in props && typeof props['data-code-language'] === 'string' ? props['data-code-language'] : undefined;

    // If the className is undefined, it is a generic inline code snippet
    if (!className) {
      if (typeof children === 'string' && children.includes('\n')) {
        return <MultilineInlineCode {...props}>{children}</MultilineInlineCode>;
      }
      return <InlineCode styleDocsComponents={styleDocsComponents}>
          {children}
        </InlineCode>;
    }
    const title = codeBlockTitle || codeLanguage;

    // If the className is defined, pass the language to the syntax highlighter
    const match = /language-(\w+)/.exec(className || '');
    const showRunButton = chatMessageId && codeIndex !== undefined;
    return <>
        <SyntaxHighlighterHeader>
          <SyntaxHighlighterHeaderTitle>
            {title && <Typography color={Color.GRAY_LIGHT} size={TypographySize.SMALL}>
                {title}
              </Typography>}
          </SyntaxHighlighterHeaderTitle>
          <SyntaxHighlighterHeaderButtons>
            <CopyButton buttonType={ButtonType.TEXT} disabled={isStreaming} getText={() => String(children)} size={Size.SMALL} textColor={Color.GRAY_LIGHT} />
            {showRunButton && <Button disabled={isStreaming} label="Run" onClick={() => {
            executeCodeMutation.mutate({
              messageId: chatMessageId,
              codeIndex
            });
          }} size={Size.SMALL} startIcon={Icon.PLAY} textColor={Color.WHITE} type={ButtonType.TEXT} />}
          </SyntaxHighlighterHeaderButtons>
        </SyntaxHighlighterHeader>
        <SyntaxHighlighter {...props} ref={ref as any} language={match ? match[1] : undefined} PreTag="div" style={xonokai}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      </>;
  },
  a: ({
    children,
    href
  }) => <Typography color={Color.NAVY} href={href ?? ''} size={TypographySize.SMALL}>
      {children}
    </Typography>,
  // Markdown table styling
  table: ({
    node,
    children
  }) => styleDocsComponents ? <StyledTable>{children}</StyledTable> : <TableMarkdownContainer>
        <TableButtonsWrapper>
          <CopyButton buttonType={ButtonType.TEXT} disabled={isStreaming} getText={() => convertTableToTSV(node)} label="Copy" size={Size.SMALL} />
          <Button disabled={isStreaming} label="Download CSV" onClick={() => saveTableAsCSV(convertTableToTSV(node))} size={Size.SMALL} startIcon={Icon.DOWNLOAD} type={ButtonType.TEXT} />
        </TableButtonsWrapper>
        <TableContainer>
          <Table>{children}</Table>
        </TableContainer>
      </TableMarkdownContainer>,
  th: ({
    children
  }) => styleDocsComponents ? <StyledTableHeader>
        <Typography color={Color.GRAY_LIGHT} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD} wrap={TypographyWrap.NO_WRAP}>
          {children}
        </Typography>
      </StyledTableHeader> : <TableHeader>{children}</TableHeader>,
  td: ({
    children
  }) => styleDocsComponents ? <StyledTableData>
        <Typography color={Color.GRAY_LIGHT} size={TypographySize.SMALL}>
          {children}
        </Typography>
      </StyledTableData> : <TableData>{children}</TableData>,
  blockquote: ({
    children
  }) => <CalloutContainer type={CalloutType.TIP}>{children}</CalloutContainer>
});
const Markdown: React.FC<MarkdownProps> = ({
  chatMessageId,
  children,
  textColor,
  codeBlockTitle,
  styleDocsComponents = false,
  isStreaming = false
}) => {
  const {
    orgSettings
  } = useOrgSettings();
  const showButton = orgSettings?.codeSandboxEnabled;
  return <ReactMarkdown components={getMarkdownComponents({
    codeBlockTitle,
    textColor,
    styleDocsComponents,
    chatMessageId,
    isStreaming
  })}
  // Using rehype plugin to add properties
  // https://github.com/orgs/remarkjs/discussions/771
  rehypePlugins={[function addCodeIndices() {
    let currentIndex = 0;
    return tree => {
      if (!showButton) {
        return;
      }
      // visit function mutates the node, so we need to assign properties
      visit(tree, {
        tagName: 'code'
      }, node => {
        // eslint-disable-next-line no-param-reassign
        node.properties = node.properties || {};

        // Detect language from className
        const className = node.properties.className || [];
        const language = className.find((cls: string) => cls.startsWith('language-'))?.replace('language-', '');

        // eslint-disable-next-line no-param-reassign
        node.properties['data-code-language'] = language;
        const isSupportedLanguage = language && SUPPORTED_EXECUTABLE_LANGUAGES.includes(language);
        if (isSupportedLanguage) {
          // eslint-disable-next-line no-param-reassign
          node.properties['data-code-index'] = currentIndex;
          currentIndex += 1;
        }
      });
    };
  }]} remarkPlugins={[remarkGfm]} css={{
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.5rem"
  }}>
      {children}
    </ReactMarkdown>;
};
export default Markdown;
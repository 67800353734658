import { tv, VariantProps } from 'tailwind-variants';
import { TabItem } from '../TabItem';
import { TabOption } from '../TabItem/TabItem';
const tabStyles = tv({
  base: 'flex min-w-[251px]',
  variants: {
    vertical: {
      true: 'flex-col',
      false: 'flex-row'
    }
  }
});
type TabNavigatorProps<TabValue extends string> = {
  activeTab: TabValue;
  setActiveTab: (tab: TabValue) => void;
  tabOptions: TabOption<TabValue>[];
};
const TabNavigator = <TabValue extends string,>({
  activeTab,
  tabOptions,
  setActiveTab,
  vertical = false
}: TabNavigatorProps<TabValue> & Prettify<VariantProps<typeof tabStyles>>) => <div className={tabStyles({
  vertical
})}>
    {tabOptions.map(tabOption => <div key={tabOption.value} css={{
    "marginRight": "1.75rem",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center"
  }} role="menu">
        <TabItem<TabValue> key={tabOption.value} isSelected={tabOption.value === activeTab} onSelect={newTab => setActiveTab(newTab)} tabOption={tabOption} vertical={vertical} />
      </div>)}
  </div>;
export default TabNavigator;
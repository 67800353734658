import _styled from "styled-components";
import { BORDER_COLORS, Color, Typography, TypographySize, TypographyWeight } from '../core';
const TabItemContainer = _styled.span<{
  $isSelected: boolean;
  $underlineColor: Color;
  $vertical: boolean;
}>(({
  $isSelected,
  $vertical,
  $underlineColor
}) => [{
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, $isSelected && {
  "cursor": "default"
},
// Selected
BORDER_COLORS[$underlineColor],
// Underline color
$isSelected && !$vertical && {
  "borderBottomWidth": "2px"
},
// Selected horizontal
$isSelected && $vertical && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(229 254 223 / var(--tw-bg-opacity))"
},
// Selected vertical
!$isSelected && {
  "cursor": "pointer"
},
// Not selected
!$isSelected && $vertical && {
  "&:hover": {
    "backgroundColor": "#8CAEBA33"
  }
},
// Not selected vertical
!$isSelected && !$vertical && {
  "&:hover": {
    "opacity": "0.5"
  }
},
// Not selected horizontal
$vertical ? {
  "marginTop": "0.75rem",
  "marginBottom": "0.75rem",
  "marginLeft": "-2rem",
  "height": "36px",
  "width": "100%",
  "borderRadius": "0.375rem",
  "paddingLeft": "1rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem"
} : {
  "paddingLeft": "0.125rem",
  "paddingRight": "0.125rem"
} // Spacing (TODO: Use gap)
]);
export interface TabOption<TabValue extends string> {
  label: string;
  value: TabValue;
}
type TabItemProp<TabValue extends string> = {
  isSelected: boolean;
  onSelect: (value: TabValue) => void;
  tabOption: TabOption<TabValue>;
  vertical: boolean;
  size?: TypographySize;
  textColor?: Color;
  underlineColor?: Color;
};
const TabItem = <TabValue extends string,>({
  tabOption,
  onSelect: handleClick,
  isSelected,
  vertical,
  size,
  textColor = Color.NAVY,
  underlineColor // Used if want underline to be a different color than text
}: TabItemProp<TabValue>) => {
  const getTypographyWeight = (): TypographyWeight => {
    switch (true) {
      // Selected and vertical
      case isSelected && vertical:
        return TypographyWeight.SEMI_BOLD;
      // Not selected and vertical
      case vertical:
        return TypographyWeight.MEDIUM;
      // Selected and not vertical
      case isSelected:
        return TypographyWeight.MEDIUM;
      // Not selected and not vertical
      default:
        return TypographyWeight.NORMAL;
    }
  };
  const getTypographySize = (): TypographySize => {
    if (size) return size;
    return vertical ? TypographySize.LARGE : TypographySize.H3;
  };
  return <TabItemContainer $isSelected={isSelected} $underlineColor={underlineColor ?? textColor} $vertical={vertical} aria-hidden onClick={() => handleClick(tabOption.value)}>
      <Typography color={textColor} size={getTypographySize()} weight={getTypographyWeight()}>
        {tabOption.label}
      </Typography>
    </TabItemContainer>;
};
export default TabItem;
const extractTextFromNode = (node: any): string => {
  if (node.type === 'text' && node.value) {
    return node.value.trim();
  }
  if (node.children) {
    return node.children.map((child: any) => extractTextFromNode(child)).filter((text: string) => text).join(' ');
  }
  return '';
};
export const convertTableToTSV = (tableNode: any): string => {
  const rows: string[][] = [];
  const headerRow = tableNode.children.find((child: any) => child.tagName === 'thead')?.children.find((tr: any) => tr.tagName === 'tr')?.children.filter((th: any) => th.tagName === 'th').map((th: any) => extractTextFromNode(th));
  if (headerRow) {
    rows.push(headerRow);
  }
  const tbody = tableNode.children.find((child: any) => child.tagName === 'tbody');
  if (tbody) {
    tbody.children.filter((tr: any) => tr.tagName === 'tr').forEach((tr: any) => {
      const row = tr.children.filter((td: any) => td.tagName === 'td').map((td: any) => extractTextFromNode(td));
      rows.push(row);
    });
  }
  return rows.map(row => row.join('\t')).join('\n');
};
export const saveTableAsCSV = (tsvData: string): void => {
  const blob = new Blob([tsvData], {
    type: 'text/csv'
  });
  const url = URL.createObjectURL(blob);
  try {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table.csv';
    a.click();
  } finally {
    URL.revokeObjectURL(url);
  }
};